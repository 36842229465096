import React, { Component } from 'react';
import { observer } from 'mobx-react';
import { action, observable } from 'mobx';
import { FormattedDate, FormattedMessage, FormattedTime } from 'react-intl';
import classNames from 'classnames';

import BookingStore from 'stores/Booking/BookingStore';
import { dateAdd } from 'helpers/datetime';

import ProductCard from 'components/ProductCard/ProductCard';
import Button from 'components/Button/Button';
import Navigation from './Navigation/Navigation';
import AlertModal from 'components/Modals/AlertModal/AlertModal';
import Icon from 'components/Icon/Icon';
import CateringSection from 'components/Panels/CateringSection/CateringSection';
import { Tabs } from 'components/Tabs/Tabs';
import { RoomRow } from 'pages/BookingPage/RoomRow/RoomRow';

import style from './BookingPage.module.scss';

enum BookingSetupState {
  SEATING = 'SEATING',
  CATERING = 'CATERING',
}

interface Props {
  store: BookingStore;
  className?: string;
}

@observer
class Setup extends Component<Props> {
  @observable setupState: BookingSetupState = BookingSetupState.SEATING;

  @action
  setSetupState = (state: BookingSetupState) => {
    this.setupState = state;
  };

  @action
  setActiveFilter = filter => {
    this.props.store.catering.setActiveFilter(filter);
  };

  @action
  toggleProduct = id => event => {
    event.stopPropagation();

    this.props.store.catering.toggleProduct(id);
  };

  showDetails = (productID: string) => async () => {
    await this.props.store.catering.showDetails(productID);
  };

  async componentDidMount() {
    const { store } = this.props;
    store.seating.event.reservations.forEach(r => {
      if (r.arrangement.id === '' && r.room.arrangements.length > 0) {
        r.arrangement = r.room.arrangements[0];
      }
    });
    store.event.reservations.forEach(r => {
      if (r.arrangement.id === '' && r.room.arrangements.length > 0) {
        r.arrangement = r.room.arrangements[0];
      }
    });
    await store.catering.fetchMenu(store.menuID);
  }

  render() {
    const { store } = this.props;
    const { stepper, seating, catering } = store;
    const { availableArrangements, setArrangement } = seating;
    const { filteredProducts, productTypes, checkedID, filter } = catering;

    return (
      <div className={style.page}>
        <section className={style.navigation}>
          <div className={style.navigationRow}>
            <Navigation store={stepper} />
          </div>

          <div className={style.order}>
            <div className={style.orderInfo}>
              <div className={style.orderInfo__date}>
                <FormattedDate
                  value={store.event.start}
                  month="long"
                  day="2-digit"
                  year="numeric"
                />
              </div>
              <ul className={style.orderInfo__list}>
                <li className={style.orderInfo__item}>
                  <Icon type="time" className={style.orderItem__icon} />
                  <span>
                    <FormattedTime value={store.event.start} />
                    {' - '}
                    <FormattedTime
                      value={dateAdd(
                        store.event.start,
                        store.event.duration,
                        'minute',
                      )}
                    />
                  </span>
                </li>
                <li className={style.orderInfo__item}>
                  <Icon type="users" className={style.orderItem__icon} />
                  <span>
                    <FormattedMessage
                      id="booking.orderSetup.personAmount"
                      values={{
                        amount: store.event.personAmount,
                      }}
                    />
                  </span>
                </li>
                {store.event.owner.id && (
                  <li className={style.orderInfo__item}>
                    <Icon type="user" className={style.orderItem__icon} />
                    <span>{store.event.owner.name}</span>
                  </li>
                )}
              </ul>
            </div>

            <div className={style.order__rooms}>
              {store.event.reservations.map(reservation => {
                const { room } = reservation;
                const isReservationSelected =
                  store.event.selectedRoomIdToSetup === room.id;

                return (
                  <React.Fragment key={room.id}>
                    <RoomRow
                      roomData={{
                        title: room.title,
                        arrangement: reservation.arrangement.title,
                      }}
                      type={isReservationSelected ? 'active' : undefined}
                    >
                      <div className={style.room__controls}>
                        <div
                          className={classNames(
                            style.btn__roomSetup,
                            this.setupState === BookingSetupState.SEATING &&
                              isReservationSelected &&
                              style.active,
                          )}
                          onClick={() => {
                            store.event.selectedRoomIdToSetup = room.id;
                            this.setSetupState(BookingSetupState.SEATING);
                          }}
                        >
                          <Icon type="seating" />
                        </div>
                        <div
                          className={classNames(
                            style.btn__roomSetup,
                            this.setupState === BookingSetupState.CATERING &&
                              isReservationSelected &&
                              style.active,
                          )}
                          onClick={() => {
                            store.event.selectedRoomIdToSetup = room.id;
                            this.setSetupState(BookingSetupState.CATERING);
                          }}
                        >
                          <Icon type="catering" />
                        </div>
                      </div>
                    </RoomRow>
                    {this.setupState === BookingSetupState.CATERING &&
                      isReservationSelected && (
                        <CateringSection store={catering} />
                      )}
                  </React.Fragment>
                );
              })}
            </div>
          </div>

          <div className={style.summary}>
            <div className={style.summary__info}>
              <div className={style.summary__title}>
                <FormattedMessage id="booking.orderSetup.summary" />
              </div>
              <div className={style.summary__total}>
                <FormattedMessage
                  id="booking.orderSetup.roomsAmount"
                  values={{
                    amount: store.event.reservations.length,
                  }}
                />
              </div>
            </div>

            <Button
              styleType="accept"
              className={style.btn}
              disabled={!seating.valid}
              onClick={stepper.nextStep}
            >
              <FormattedMessage id="button.continue" />
            </Button>
          </div>
        </section>

        <section className={style.content}>
          <div className={style.header}>
            <h1 className={style.header__title}>
              {store.event.selectedReservation?.room?.title ?? ''}
            </h1>

            {this.setupState === BookingSetupState.CATERING && (
              <Tabs
                tabs={productTypes.map(type => ({
                  id: type,
                  title: type,
                }))}
                onTabClick={this.setActiveFilter}
                activeTabId={filter}
              />
            )}
          </div>

          {this.setupState === BookingSetupState.SEATING && (
            <ul className={style.products}>
              {availableArrangements.map(product => (
                <li key={product.id}>
                  <ProductCard
                    {...product}
                    description={
                      <FormattedMessage
                        id="booking.seating.capacity.message"
                        values={{
                          capacity: product.capacity,
                        }}
                      />
                    }
                    checked={
                      store.event.selectedReservation?.arrangement.id ===
                        product.id ||
                      product.id === availableArrangements[0]?.id
                    }
                    onClick={setArrangement(product)}
                    className={style.product}
                  />
                </li>
              ))}
            </ul>
          )}

          {this.setupState === BookingSetupState.CATERING && (
            <ul
              className={classNames(style.products, style.products__catering)}
            >
              {filteredProducts.map(product => (
                <li key={product.id} onClick={this.showDetails(product.id)}>
                  <ProductCard
                    {...product}
                    checked={checkedID.includes(product.id)}
                    onClick={this.toggleProduct(product.id)}
                    className={classNames(
                      style.product,
                      style.product__catering,
                    )}
                  />
                </li>
              ))}
            </ul>
          )}
        </section>

        {seating.showTermError && (
          <AlertModal
            message="error.possibleTerm"
            onClose={() => (seating.showTermError = false)}
          />
        )}

        {catering.showTermError && (
          <AlertModal
            message={
              store.event.id ? 'error.possibleTerm' : 'error.possible.create'
            }
            onClose={() => (catering.showTermError = false)}
          />
        )}
      </div>
    );
  }
}

export default Setup;
