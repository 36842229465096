import React, { Component } from 'react';
import { observer } from 'mobx-react';
import classNames from 'classnames';
import { FormattedDate, FormattedMessage, FormattedTime } from 'react-intl';
import { RouteComponentProps } from 'react-router';

import { dateAdd } from 'helpers/datetime';
import EventStore from 'stores/EventStore/EventStore';
import CateringStore from 'stores/CateringStore';
import AppRouter from 'stores/AppRouter';

import Button from 'components/Button/Button';
import FormattedCurrency from 'components/FormattedCurrency/FormattedCurrency';
import Icon from 'components/Icon/Icon';
import ProductCard from 'components/ProductCard/ProductCard';
import { Tabs } from 'components/Tabs/Tabs';
import CateringSection from 'components/Panels/CateringSection/CateringSection';

import style from './CateringPage.module.scss';

type Props = RouteComponentProps<{ id: string; roomId: string }>;

@observer
class CateringPage extends Component<Props> {
  eventStore = new EventStore();
  cateringStore = new CateringStore(this.eventStore.event, true);
  eventID: string;

  constructor(props) {
    super(props);

    this.eventID = this.props.match.params && this.props.match.params.id;
    this.eventStore.event.selectedRoomIdToSetup = this.props.match.params.roomId;
  }

  async componentDidMount() {
    await this.eventStore.fetchEvent(this.eventID);
    // fetch menu after event is fetched to get correct prices for menu items
    await this.cateringStore.fetchMenu();
  }

  onProductClick = id => event => {
    event.stopPropagation();

    this.cateringStore.toggleProduct(id);
  };

  setActiveFilter = filter => {
    this.cateringStore.setActiveFilter(filter);
  };

  showDetails = initialID => async () => {
    await this.cateringStore.showDetails(initialID);
  };

  saveMenu = async () => {
    if (this.eventStore.event.selectedReservation) {
      await this.cateringStore.saveAdditionalMenu(
        this.eventStore.event.selectedReservation.room.id,
      );
    }
    AppRouter.goBack();
  };

  render() {
    const { event } = this.eventStore;
    const {
      filteredProducts,
      productTypes,
      checkedID,
      filter,
    } = this.cateringStore;

    return (
      <div className={style.page}>
        <section className={style.navigation}>
          <div className={style.navigationRow}>
            <Button className={style.btn__arrow} onClick={AppRouter.goBack}>
              <Icon
                type="arrowLeft"
                className={classNames(style.icon, style.icon__arrow)}
              />
            </Button>
            <h2 className={style.title}>
              <FormattedMessage id="additional.menu" />
            </h2>
          </div>
          <div className={style.aside__data}>
            <div className={style.roomInfo}>
              <h3 className={classNames(style.roomTitle, style.roomInfoLine)}>
                {this.eventStore.event.selectedReservation?.room.title}
              </h3>
              <div className={style.roomInfoLine}>
                <FormattedDate
                  value={event.start}
                  year="numeric"
                  month="long"
                  day="2-digit"
                />
              </div>
              <div className={classNames(style.roomInfoLine, style.duration)}>
                <Icon type="time" className={style.icon} />
                <FormattedTime value={event.start} />
                &nbsp;-&nbsp;
                <FormattedTime
                  value={dateAdd(event.start, event.duration, 'minute')}
                />
              </div>
            </div>
            <div className={style.menu}>
              {this.cateringStore.extraMenu.length ? (
                <CateringSection store={this.cateringStore} extraMenu />
              ) : (
                <div className={style.emptyState}>
                  <Icon type="emptyDish" className={style.emptyState__icon} />
                  <div className={style.text}>
                    <FormattedMessage id="catering.empty.message" />
                  </div>
                </div>
              )}
            </div>
          </div>
          <div
            className={classNames(
              style.summary__wrapper,
              this.cateringStore.canSaveExtraMenu && style.visible,
            )}
          >
            <div className={style.summary}>
              <div className={style.summary__info}>
                <div className={style.summary__title}>
                  <FormattedMessage id="booking.orderSetup.summary" />
                </div>
                <div className={style.summary__total}>
                  <FormattedCurrency
                    value={this.cateringStore.extraMenuPrice}
                  />
                </div>
              </div>
              <Button styleType="accept" onClick={this.saveMenu}>
                <FormattedMessage id="button.save" />
              </Button>
            </div>
          </div>
        </section>
        <section className={style.content}>
          <div className={style.header}>
            <Tabs
              tabs={productTypes.map(type => ({
                id: type,
                title: type,
              }))}
              onTabClick={this.setActiveFilter}
              activeTabId={filter}
            />
          </div>
          <ul className={style.products}>
            {filteredProducts.map(product => (
              <li key={product.id} onClick={this.showDetails(product.id)}>
                <ProductCard
                  {...product}
                  checked={checkedID.includes(product.id)}
                  onClick={this.onProductClick(product.id)}
                  className={style.product}
                />
              </li>
            ))}
          </ul>
        </section>
      </div>
    );
  }
}

export default CateringPage;
