import { action, computed, observable } from 'mobx';
import {
  CanEditCateringType,
  Event,
  EventStatus,
  InvoiceStatus,
  UserPriceGroup,
} from 'types/entities';

import { EventReservationModel } from 'stores/Models/EventReservationModel';
import RootStore from 'stores/RootStore';

const DEFAULT_CLEANING = 15;
const DEFAULT_PREPARATION = 15;

const initialOwner = {
  id: '',
  name: '',
};

class EventModel implements Event {
  @observable owner: { id: string; name: string } = initialOwner;
  @observable id = '';
  @observable nextGenId;
  @observable visitors;

  @observable createdById: string = '';
  @observable comment;
  @observable cleaning = DEFAULT_CLEANING;
  @observable duration = 30;
  @observable invoice: {
    id: string;
    number: string;
    status: InvoiceStatus;
  };
  @observable personAmount = 1;
  @observable preparation = DEFAULT_PREPARATION;
  @observable priceGroupBooking = RootStore.user.priceGroup;
  @observable reservations: EventReservationModel[] = [];
  @observable start = new Date();
  @observable status = EventStatus.inApproval;
  @observable roomServiceCost = 0;
  @observable totalPrice = 0;
  @observable title = '';
  @observable version?: number;
  @observable duplicated = false;
  @observable canEdit: boolean = true;
  @observable canEditCatering: CanEditCateringType = CanEditCateringType.all;

  @observable acceptAGB = false;
  @observable acceptCorona = false;

  @observable private _selectedRoomIdToSetup = '';

  get selectedRoomIdToSetup(): string {
    return this._selectedRoomIdToSetup
      ? this._selectedRoomIdToSetup
      : this.reservations[0]?.room.id;
  }

  set selectedRoomIdToSetup(roomId: string) {
    this._selectedRoomIdToSetup = roomId;
  }

  @computed
  get selectedReservation(): EventReservationModel | undefined {
    return (
      this.reservations.find(
        reservation => reservation.room.id === this._selectedRoomIdToSetup,
      ) ?? this.reservations[0]
    );
  }

  @computed
  get roomsIds() {
    return this.reservations.map(reservation => reservation.room.id);
  }

  @action
  resetSelectedRoomIdToSetup() {
    this._selectedRoomIdToSetup = '';
  }

  @action
  resetReservations() {
    this.reservations = [];
  }

  @action.bound
  setOwner(id: string, name: string, priceGroup: UserPriceGroup) {
    this.owner = {
      id,
      name,
    };
    this.priceGroupBooking = priceGroup;
  }

  @action
  update(data: any) {
    Object.entries(data).forEach(([key, value]) => {
      this[key] = value;
    });
    this.reservations = data.reservations.map(reservation => {
      return new EventReservationModel({
        reservationData: reservation,
      });
    });
    this.owner.id = data.createdById;
    // set rules as accepted on edit
    this.acceptAGB = true;
    this.acceptCorona = true;
  }
}

export default EventModel;
