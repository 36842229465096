import { action, computed, observable, reaction } from 'mobx';

import { Arrangement, EventStatus } from 'types/entities';
import BookingStore from './BookingStore';
import { dateAdd } from 'helpers/datetime';
import { getEventPreparationStart } from './helpers';
import RoomModel from 'stores/Models/RoomModel';

class SeatingStore {
  @observable showTermError = false;

  private readonly bookingStore: BookingStore;

  @computed
  get event() {
    return this.bookingStore.event;
  }

  @computed
  get availableArrangements(): Arrangement[] {
    const { rooms } = this.bookingStore;
    const selectedRoom = rooms.find(
      room => room.id === this.event.selectedRoomIdToSetup,
    ) as RoomModel;

    return selectedRoom.arrangements;
  }

  @computed
  get isArrangementsChecked(): boolean {
    return this.event.reservations.every(
      reservation => !!reservation.arrangement.id,
    );
  }

  constructor(bookingStore: BookingStore) {
    this.bookingStore = bookingStore;

    reaction(
      () => this.showTermError,
      value =>
        value ? setTimeout(() => (this.showTermError = false), 5000) : null,
    );
  }

  @action.bound
  setArrangement = (product: Arrangement) => () => {
    if (!this.canEdit) {
      this.showTermError = true;
      return;
    }

    if (this.bookingStore.event.selectedReservation) {
      this.bookingStore.event.selectedReservation.arrangement = product;
    }
  };

  @computed
  get canEdit() {
    return (
      this.bookingStore.canEdit &&
      (!this.bookingStore.hasCatering ||
        this.bookingStore.event.status === EventStatus.inApproval ||
        this.bookingStore.event.status === EventStatus.declined ||
        (this.bookingStore.event.status === EventStatus.accepted &&
          Date.now() <
            +dateAdd(
              getEventPreparationStart(this.bookingStore.event),
              -24,
              'hour',
            )))
    );
  }
}

export default SeatingStore;
