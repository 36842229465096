import { action, computed, observable } from 'mobx';
import { User } from 'types/entities';
import httpFacade from 'http/httpFacade';
import Log from 'helpers/log';
import { Option } from 'components/Form/Fields/Select/Select';

export class UsersStore {
  @observable users: User[] = [];
  @observable isLoading: boolean = false;
  @observable searchValue = '';

  private readonly isSecretaryRole: boolean = false;

  constructor(isSecretaryRole = false) {
    this.isSecretaryRole = isSecretaryRole;
  }

  @action.bound
  setSearch(value: string) {
    this.searchValue = value;
  }

  @computed
  get usersOptions(): Array<Option<string>> {
    return Object.values(this.users).map(user => ({
      label: `${user.company ? `${user.company}, ` : ''}${user.firstName} ${
        user.lastName
      }`,
      value: user.id,
      payload: user.priceGroup,
    }));
  }

  @computed
  get filteredUsers() {
    const value = this.searchValue.toLocaleLowerCase();

    return this.searchValue
      ? this.users.filter(
          item =>
            item.firstName.toLocaleLowerCase().includes(value) ||
            item.lastName.toLocaleLowerCase().includes(value) ||
            item.email.toLocaleLowerCase().includes(value),
        )
      : this.users;
  }

  @action.bound
  async fetchUsers() {
    try {
      this.isLoading = true;

      const { data } = this.isSecretaryRole
        ? await httpFacade.users.fetchUsersSecretary()
        : await httpFacade.users.fetchUsers();

      this.users = data;
    } catch (error) {
      Log.warn(error);
    } finally {
      this.isLoading = false;
    }
  }
}
