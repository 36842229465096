import React from 'react';
import classNames from 'classnames';

import style from './Icon.module.scss';

import { ReactComponent as accept } from './icons/accept.svg';
import { ReactComponent as accessDenied } from './icons/accessDenied.svg';
import { ReactComponent as arrow } from './icons/arrowLeft.svg';
import { ReactComponent as bill } from './icons/bill.svg';
import { ReactComponent as bill48 } from './icons/bill48.svg';
import { ReactComponent as chair } from './icons/chair.svg';
import { ReactComponent as clock } from './icons/clock.svg';
import { ReactComponent as clock48 } from './icons/clock48.svg';
// import { ReactComponent as coffeeBreak } from './icons/coffee break.svg';
import { ReactComponent as declined } from './icons/declined.svg';
// import { ReactComponent as dish } from './icons/dish.svg';
// import { ReactComponent as drink } from './icons/drink.svg';
import { ReactComponent as house } from './icons/house.svg';
import { ReactComponent as sionum } from './icons/logo-conf.svg';
import { ReactComponent as logout } from './icons/logout.svg';
import { ReactComponent as menu } from './icons/menu.svg';
// import { ReactComponent as microphone } from './icons/microphone.svg';
import { ReactComponent as minus } from './icons/minus.svg';
import { ReactComponent as notification } from './icons/notification.svg';
import { ReactComponent as search } from './icons/search.svg';
import { ReactComponent as plus } from './icons/plus.svg';
import { ReactComponent as pdf } from './icons/pdf.svg';
import { ReactComponent as printer } from './icons/printer.svg';
import { ReactComponent as reload } from './icons/reload.svg';
import { ReactComponent as reporting } from './icons/reporting.svg';
import { ReactComponent as reporting48 } from './icons/reporting48.svg';
import { ReactComponent as serverError } from './icons/500.svg';
import { ReactComponent as pin } from 'components/Icon/icons/pin.svg';
import { ReactComponent as pinned } from 'components/Icon/icons/pinned.svg';
import { ReactComponent as trash } from './icons/trash.svg';
import { ReactComponent as capacity } from './icons/capacity.svg';
import { ReactComponent as price } from './icons/price.svg';
import { ReactComponent as mazars } from './icons/mazars-logo.svg';
import { ReactComponent as aes } from './icons/aes-logo.svg';
import { ReactComponent as aesLogo } from './icons/aes-logo-s.svg';
import { ReactComponent as info } from './icons/info.svg';
import { ReactComponent as dishCovid } from './icons/dish-covid.svg';
import { ReactComponent as iconOk } from './icons/icon-ok.svg';
import { ReactComponent as iconNotOk } from './icons/icon-not-ok.svg';
import { ReactComponent as profile } from './icons/profile.svg';
import { ReactComponent as user } from './icons/user.svg';
import { ReactComponent as users } from './icons/users.svg';
import { ReactComponent as time } from './icons/time.svg';
import { ReactComponent as seating } from './icons/seating.svg';
import { ReactComponent as catering } from './icons/catering.svg';
import { ReactComponent as close } from './icons/close.svg';
import { ReactComponent as calendar } from './icons/calendar.svg';
import { ReactComponent as message } from './icons/message.svg';
import { ReactComponent as emptyDish } from './icons/emptyDish.svg';
import { ReactComponent as gandh } from './icons/gandh-logo.svg';
import { ReactComponent as ccsc } from './icons/ccsc-logo.svg';
import { ReactComponent as sbi } from './icons/sbi-logo.svg';

const icons = {
  dishCovid,
  info,
  mazars,
  aes,
  aesLogo,
  accept,
  accessDenied,
  arrowBottom: arrow,
  arrowLeft: arrow,
  arrowRight: arrow,
  arrowTop: arrow,
  bill,
  bill48,
  chair,
  clock,
  clock48,
  // coffeeBreak,
  declined,
  // dish,
  // drink,
  house,
  sionum,
  logout,
  menu,
  // microphone,
  minus,
  notification,
  pdf,
  plus,
  printer,
  reload,
  reporting,
  reporting48,
  search,
  serverError,
  pin,
  pinned,
  trash,
  price,
  capacity,
  iconOk,
  iconNotOk,
  profile,
  user,
  users,
  time,
  seating,
  catering,
  close,
  calendar,
  message,
  emptyDish,
  gandh,
  ccsc,
  sbi,
};

const iconsClassName = {
  arrowRight: style.rotate180,
  arrowTop: style.rotate90,
  arrowBottom: style.rotate270,
};

interface Props {
  type: keyof typeof icons;
  className?: string;
  onClick?: (event?: any) => void;
}

const Icon = ({ type, className, onClick }: Props) => {
  const SVGIcon = icons[type] || null;
  const iconClassName = iconsClassName[type] || null;

  return (
    SVGIcon && (
      <i className={classNames(style.icon, className)} onClick={onClick}>
        <SVGIcon className={classNames(iconClassName, style.svg)} />
      </i>
    )
  );
};

export default Icon;
