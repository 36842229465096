import { action, observable } from 'mobx';

import Localization from './Localization';
import UserStore from './UserStore';

import httpFacade from 'http/httpFacade';
import { convertParamsToObject } from 'http/Api/root/helpers';

import { Time, stringToTime } from 'helpers/datetime';
import Log from 'helpers/log';

export type AppSchema = 'sionum' | 'mazars' | 'aes' | 'gandh' | 'ccsc' | 'sbi';

interface Config {
  eventTimeMin: Time;
  eventTimeMax: Time;
  eventStep: number;
  eventDurationMin: number;
  eventDurationMax: number;
  eventDurationDefault: number;
  appSchema: AppSchema;
  agbRules: string;
  coronaRules: string;
  publicHolidays: string[];
  mailsForBooking: { enabled: string[] };
  mailForBookingReject: string;
  telForBookingReject: string;
}

class RootStore {
  @observable localization = new Localization();
  @observable user = new UserStore();
  @observable config: Config;
  @observable loading = true;
  @observable internalServerError = false;
  @observable accessDeniedError = false;

  @action.bound
  async init() {
    try {
      this.loading = true;
      await Promise.all([this.fetchConfig(), this.localization.init()]);

      this.loading = false;
    } catch (error) {
      Log.error('initialization error');
    }
  }

  @action.bound
  async fetchConfig() {
    try {
      const { data: configParams = [] } = await httpFacade.root.fetchConfig();
      const {
        data: defaultConfig,
      } = await httpFacade.root.fetchDefaultConfig();

      const config = {
        ...defaultConfig,
        ...convertParamsToObject(configParams),
      };

      this.config = {
        ...config,
        eventTimeMin: stringToTime(config.eventTimeMin),
        eventTimeMax: stringToTime(config.eventTimeMax),
        eventStep: Number(config.eventStep),
        eventDurationMin: Number(config.eventDurationMin),
        eventDurationMax: Number(config.eventDurationMax),
        eventDurationDefault: Number(config.eventDurationDefault),
      };

      this.setSchema(this.config.appSchema);
      this.loadFont(this.config.appSchema);
    } catch (error) {
      Log.error(error);
    }
  }

  setSchema = (schema: AppSchema) => {
    const title = document.getElementById('app-title');
    const favicon = document.getElementById('app-favicon');

    document.body.className = schema;

    if (title && favicon) {
      title.innerText = 'sionum.meeting';
      favicon.setAttribute('href', `${process.env.PUBLIC_URL}/${schema}.png`);
    }
  };

  loadFont = async (schema: AppSchema) => {
    const fontFaces: FontFace[] = [];

    if (schema === 'ccsc') {
      const fontName = 'Helvetica Condensed';

      fontFaces.push(
        new FontFace(
          fontName,
          'url(/assets/fonts/HelveticaCondensed/HelveticaCondensed.ttf)',
          {
            style: 'normal',
            unicodeRange: 'U+000-5FF',
            weight: '400',
          },
        ),
        new FontFace(
          fontName,
          'url(/assets/fonts/HelveticaCondensed/HelveticaCondensedMedium.otf)',
          {
            style: 'normal',
            unicodeRange: 'U+000-5FF',
            weight: '500',
          },
        ),
        new FontFace(
          fontName,
          'url(/assets/fonts/HelveticaCondensed/HelveticaCondensedBold.otf)',
          {
            style: 'normal',
            unicodeRange: 'U+000-5FF',
            weight: '700',
          },
        ),
      );

      document.body.style.fontFamily = `${fontName}, 'sans-serif'`;
    } else {
      const fontName = 'Lato';

      fontFaces.push(
        new FontFace(fontName, 'url(/assets/fonts/Lato/Lato.ttf)', {
          style: 'normal',
          unicodeRange: 'U+000-5FF',
          weight: '400',
        }),
        new FontFace(fontName, 'url(/assets/fonts/Lato/LatoBold.ttf)', {
          style: 'normal',
          unicodeRange: 'U+000-5FF',
          weight: '700',
        }),
      );

      document.body.style.fontFamily = `${fontName}, 'sans-serif'`;
    }

    fontFaces.forEach(fontFace => {
      fontFace.load().then(font => document.fonts.add(font));
    });
  };
}

export default new RootStore();
