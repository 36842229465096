import React, { Component } from 'react';
import { observer } from 'mobx-react';
import { NavLink } from 'react-router-dom';

import style from './AppHeader.module.scss';

import Icon from 'components/Icon/Icon';
import AppDate from 'components/AppDate/AppDate';
import AppProfile from 'components/AppProfile/AppProfile';

import RootStore from 'stores/RootStore';

import { ROUTES } from 'routes/routes';
import { getDefaultRouteByRole, getRole, Roles } from 'helpers/roles';

@observer
class AppHeader extends Component {
  render() {
    const { user } = RootStore;
    const navigationLinksAccess = {
      home: !getRole(Roles.ADMIN),
      users: getRole(Roles.ADMIN),
      menu: !getRole(Roles.CATERER_STAFF) && !getRole(Roles.ADMIN),
    };
    const { home, users, menu } = navigationLinksAccess;

    return (
      <header className={style.header}>
        <div className={style.linkWrapper}>
          <Icon type={this._getLogo()} className={style.logo} />
          {home && (
            <NavLink
              to={getDefaultRouteByRole()}
              className={style.link}
              activeClassName={style.link__active}
            >
              <Icon type="house" />
            </NavLink>
          )}
          {users && (
            <NavLink
              to={ROUTES.users}
              className={style.link}
              activeClassName={style.link__active}
            >
              <Icon type="users" className={style.iconUsers} />
            </NavLink>
          )}
          {menu && (
            <NavLink
              to={ROUTES.menu}
              className={style.link}
              activeClassName={style.link__active}
            >
              <Icon type="menu" />
            </NavLink>
          )}
        </div>
        <div className={style.contentWrapper}>
          <AppDate withTime />
          <AppProfile user={user} />
        </div>
      </header>
    );
  }

  private _getLogo() {
    switch (RootStore.config.appSchema) {
      case 'gandh':
        return 'gandh';
      case 'ccsc':
        return 'ccsc';
      case 'sbi':
        return 'sbi';
      default:
        return 'aesLogo';
    }
  }
}

export default AppHeader;
