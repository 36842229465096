import React, { Component } from 'react';
import { observer } from 'mobx-react';
import {
  FormattedMessage,
  WrappedComponentProps,
  injectIntl,
} from 'react-intl';
import { RouteComponentProps } from 'react-router';
import classNames from 'classnames';
import { reaction } from 'mobx';

import style from './MenuPage.module.scss';

import Grid from 'components/Grid/Grid';
import Select from 'components/Form/Fields/Select/Select';
import InvoiceItem from 'components/Grid/Items/InvoiceItem';
import InvoicePanel from 'components/Panels/Invoice/InvoicePanel';
import { Types } from 'components/Grid/types';
import { DEFAULT_INVOICE_SORTING } from 'pages/config';

import InvoiceGridStore, {
  invoiceConfigColumnStartAccessor,
} from 'stores/InvoiceGridStore';
import StorageStore, { FILTERS } from 'stores/FiltersStore';
import AppRouter from 'stores/AppRouter';

import { Invoice } from 'types/entities';

export const invoiceConfig: Types<Invoice> = {
  columns: [
    {
      id: 'start',
      header: <FormattedMessage id="invoiceList.header.title.date" />,
      accessor: invoiceConfigColumnStartAccessor,
      sortable: true,
    },
    {
      id: 'number',
      header: <FormattedMessage id="invoiceList.header.title.costCenter" />,
      sortable: true,
    },
    {
      id: 'event.rooms',
      header: <FormattedMessage id="invoiceList.header.title.room" />,
      sortable: false,
    },
    {
      id: 'event.totalPrice',
      header: (
        <span className={style.grid__totalPrice}>
          <FormattedMessage id="invoiceList.header.title.total" />
        </span>
      ),
      sortable: true,
    },
  ],
  item: InvoiceItem,
};

type Props = RouteComponentProps<{ id: string }>;

@observer
class InvoiceGrid extends Component<WrappedComponentProps & Props> {
  store = new InvoiceGridStore(AppRouter.getHashValue());

  async componentDidMount() {
    await this.store.fetchInvoices();

    this.changeActiveElement(this.store.selectedInvoiceID);

    reaction(
      // @ts-ignore
      () => this.props.location.state && this.props.location.state.id,
      _hash => this.changeActiveElement(AppRouter.getHashValue()),
    );
  }

  changeActiveElement(id, scrollIntoView = true) {
    const element = document.getElementById(id);

    if (element) {
      if (scrollIntoView) {
        element.scrollIntoView();
      } else {
        element.removeAttribute('id');

        window.location.hash = id;

        element.setAttribute('id', id);
      }

      this.store.selectedInvoiceID = id;
    }
  }

  onItemClick = ({ id }) => this.changeActiveElement(id, false);

  changeStatus = value => {
    StorageStore.saveFilter(FILTERS.GRID__INVOICE__STATUS, value);

    this.store.status = value;
  };

  changePeriod = value => {
    StorageStore.saveFilter(FILTERS.GRID__INVOICE__PERIOD, value);

    this.store.period = value;
  };

  render() {
    const { data, period, status } = this.store;

    return (
      <div className={style.content__invoice}>
        <div className={style.filters}>
          <FormattedMessage id="eventsList.filters.title" />

          <Select
            className={style.select}
            classNamePrefix="select"
            value={period}
            onChange={this.changePeriod}
            options={InvoiceGridStore.periodOptions}
            disabled={this.store.pending}
          />

          <Select
            className={style.select}
            value={status}
            onChange={this.changeStatus}
            options={InvoiceGridStore.statusOptions}
            disabled={this.store.pending}
          />
        </div>

        <div className={style.wrapper__invoice}>
          <Grid
            loading={this.store.pending}
            data={data}
            config={invoiceConfig}
            className={style.grid__invoice}
            onRowClick={this.onItemClick}
            activeRow={this.store.activeItem}
            onSort={config => (this.store.sortConfig = config)}
            defaultSorted={DEFAULT_INVOICE_SORTING}
          />

          <InvoicePanel
            store={this.store}
            className={classNames(style.panel, style.panel__invoice)}
          />
        </div>
      </div>
    );
  }
}

export default injectIntl(InvoiceGrid);
